export const STATUS_200 = 200;
export const STATUS_300 = 300;
export const STATUS_400 = 400;
export const STATUS_401 = 401;
export const STATUS_403 = 403;
export const SEARCH_LIMIT = 50;
export const DATA_LIMIT = 20;
export const profileScore_25 = 25;
export const profileScore_50 = 50;
export const profileScore_70 = 70;
export const profileScore_75 = 75;
export const likeValue = 1;
export const dislikeValue = 2;
export const noLikeDislikeValue = 0;
export const variantOnValue = 'true';
export const variantOffValue = 'false';
export const Authentication = {
  REDIRECT_LOGIN: 'redirectToLogin',
  TOKEN: 'token',
  USER_INFO: 'userInfo',
  TIME: 'getTime',
};
export const strengthLevels = [
  {
    value: 0,
    label: 'Very Weak',
  },
  {
    value: 25,
    label: 'Weak',
  },
  {
    value: 50,
    label: 'Average',
  },
  {
    value: 75,
    label: 'Strong',
  },
  {
    value: 100,
    label: 'Very Strong',
  },
];
export const strengthLevels2 = [
  {
    value: 0,
    label: 'Unknown',
  },
  {
    value: 25,
    label: 'Weak',
  },
  {
    value: 50,
    label: 'Average',
  },
  {
    value: 75,
    label: 'Strong',
  },
  {
    value: 100,
    label: 'Market Leader',
  },
];
export const teamSizeMarks = [
  {
    value: 0,
    label: '1-50',
  },
  {
    value: 25,
    label: '51-200',
  },
  {
    value: 50,
    label: '201-1000',
  },
  {
    value: 75,
    label: '1001-5000',
  },
  {
    value: 100,
    label: '5000+',
  },
];

export const fundingMarks = [
  {
    value: 0,
    label: 'Minimal',
  },
  {
    value: 25,
    label: '$1-10M',
  },
  {
    value: 50,
    label: '$11-50M',
  },
  {
    value: 75,
    label: '$51-500M',
  },
  {
    value: 100,
    label: '$501M+',
  },
];
export const toastMsg = {
  userLogged: `User logged in successfully.`,
  userFailed: `User logged in failed.`,
  userLogout: `User logged out successfully.`,
  regComplete: `Congratulations! Your registration has been completed successfully.`,
  accountDoesNotExist: `Account with this email does not exist.`,
  downloadFail: `Failed to download file.`,
  tokenExpire: 'Token expireded.',
  checkEmail: `Please check your email to verify.`,
  errorMssg: `Some error occurred.`,
  enterOtp: `Please enter OTP `,
};
export const profileMsg = {
  fillSection: `  Fill out at least 50% of each section for the best results, but
    don't worry about hitting 100%. Focus on the fields where you have
    the most passion and thoughts. Our AI uses this info to generate
    your custom business ideas, determine founder-idea fit, and create
    other tailored guidance for you.`,
};
export const profileLabels = {
  selectOption: 'Please select your options',
};

export const labels = {
  commonLabels: {
    cancelLabel: 'Cancel',
    submitLabel: 'Submit',
    deleteLabel: 'Delete',
  },
  headers: {
    invite: 'Invite',
    userMenu: {
      account: 'Account',
      workSpace: 'Workspace',
      helpFaq: 'Help and FAQ',
      logOut: 'Logout',
    },
  },
  quickIdeas: {
    heading: 'Top 5 Pathways - For You',
    detailButton: 'Details',
  },
  profileStatus: {
    heading: 'Profile Status',
    content1:
      'Add more profile data so our AI can generate custom ideas and tailored guidance for you. Filling in your profile helps you think through important considerations that will impact your journey as an entrepreneur.',
    content2:
      'If you add more profile data our AI will generate smarter ideas and better tailored guidance for you. Filling in more of your profile can also help you think through important considerations that will impact your journey as an entrepreneur',
    content3:
      'Your profile looks good. If you add even more data to it, our AI will create ideas and guidance that match you even better.',
    content4:
      'Wow! Your profile looks great!. Our AI is using it to create highly-customized ideas and guidance specific to who you are and your goals.',
    editButton: 'Edit Profile',
  },
  profile: {
    title: 'Profile',
    resumeBtn: 'Download Resume',
    uploadResume: 'Upload Resume',
    accessDenied: 'Access Denied',
    resumeBtnDisabled:
      'Please generate "Career Pathway" to access this option.',
    tipLabel1: 'Tip',
    completeProfileDialogTitle: 'Please complete your basic details first',
    completeProfileDialogMsg:
      'You have not completed your profile in account. Please complete it first then come again.',
    goToAccount: 'Go to account',
    successTipMsg1: 'After updating your profile, go to',
    successTipMsg2:
      ' to see your AI-generated ideas. The data you entered auto-saves.',
  },
  profileCompleteModal: {
    title: 'Your profile is incomplete!',
    dialogMsg:
      'Please complete atleast 50 percent of your profile then come again.',
    btnLabel: 'Go to profile',
  },
  setupStatus: {
    heading: '6 Steps To Success',
  },
  welcome: {
    heading: 'Welcome to Pathways!',
    subHeading1: 'Fill out some of your ',
    subHeading2: ' to get started, then click "Refresh Ideas" on the ',
    subHeading3: ' page.',
  },
  accounts: {
    title: 'Account',
    generalTabLabel: 'General',
    planTab: 'Plan and Payments',
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    countryLabel: 'Country',
    roleLabel: 'Role',
    saveBtnLabel: 'Save',
    emailLabel: 'Email',
    editEmailLabel: 'Edit email',
    cancelBtnLabel: 'Cancel',
    planLabel: 'Plan',
    upgradeLabel: 'Upgrade',
    downgradeLabel: 'Downgrade',
    cancelSubscription: 'Cancel Subscription',
    proCredit: 'Pro Credits',
    included: 'Included:',
    used: 'Used: ',
    payments: 'Payments',
    nextAutoPay: 'Next autopay on:',
    updatePaymentMethod: 'Update Payment Method',
    paymentHistory: 'Payment History',
    proLabel: 'PRO',
    selectPlanHeading: 'Select a plan',
    learnMore: 'Learn More',
    upgradeNow: 'Upgrade Now',
    selectPlan:
      'Select a plan and upgrade now to get expanded access to all the powerful Pro features.',
    accountsOtpModal: {
      otpHeading: 'Enter OTP',
      otpSentLabel: 'An OTP has been sent to',
      enterOtpLabel: 'Please enter the OTP to update to your email address.',
      cancelBtnLabel: 'Cancel',
      resendBtnLabel: 'Resend OTP',
    },
    successModal: {
      emailChangeSuccess: 'Email changed successfully!',
      userMsg:
        'Your email has been changed successfully. Please re-login with the new email.',
      btnLabel: 'Okay',
    },
    roleChangeModal: {
      title: 'Role Change',
      subHeading: 'Warning!',
      paraText:
        'Role change will result in complete removal of your current profile information. Do you wish to proceed?',
      agreeBtnLabel: 'Yes',
      disagreeBtnLabel: 'No',
    },
  },
  businessIdeas: {
    title: 'Business Ideas',
    addIdeaModal: {
      title: 'Add Manual Idea',
      infoText:
        "Note, manually-created ideas are locked by default. If you later don't want the idea you can unlock it. You can even thumbs-down it if you want to tell the AI to avoid ideas like it.",
      nameLabel: 'Name',
      summaryLabel: 'Summary',
      namePlaceholder: 'Idea Name',
      summaryPlaceholder: 'Briefly describe your idea in 1-2 sentences',
    },
    businessIdeasContainer: {
      detailButton: 'Details >',
      edtReasearchButton: 'Edit in Research >',
      shareButton: 'Share',
      badgeLabel1: 'Manual',
      undoBtn: 'Click to undo',
      ideaDislikedMsg: 'Idea rejected. Will be replaced upon refresh.',
    },
    businessIdeasHeader: {
      addIdea: 'Add Idea',
      refreshIdeas: 'Refresh Ideas',
      generatingIdeas: 'Generating Ideas...',
      helpText1:
        '= Prevent an idea from being hidden by future idea refreshes. Refreshes happen when you update your profile or take actions here like creating variants.',
      helpText2:
        '= Tell our AI that you like an idea. This will encourage it to produce similar or tangential ideas.',
      helpText3:
        '= Tell our AI that you dislike an idea. This will help it know what you want to avoid.',
      helpText4: '= Create variations of the idea.',
    },
    feedbackModal: {
      title: 'Your Reason',
      likePlaceholder: 'Reason you like this idea',
      dislikePlaceholder: "Reason you don't like this idea",
      noteTitle: 'Note',
      noteText:
        'This idea will be hidden but it will still be used in the background to teach our AI your preferences.',
      NoteLockText:
        "This idea is locked so it won't be removed from your current ideas. Unlock it to have it replaced with a new idea on the next refresh.",
      submitBtnLabel: 'Submit',
    },
  },
  businessPlan: {
    title: 'Business Plan',
    selectAnIdea: 'Please select an Idea to continue.',
    subTitle: 'Complete and export a business plan for your idea',
    exportBtnLabel: 'Export and Download',
    samplePlanLabel: 'Download sample exported plan',
    ideaSelector: {
      title: 'Idea',
      subTitle: 'Pick an idea to work with',
    },
    syncedWithIdea: 'synchornized with idea details',
    financialAiTooltip: 'Ai Autofill will complete any empty cells.',
    financialAiMsg: 'Autofill working, typically takes 1-2 minutes...',
  },
  filter: {
    title: 'Filter',
    topFiveTitle: 'Top 5',
    consideringTitle: 'Considering',
    notRightTitle: 'Not Quite Right',
    tableHeaders: {
      rankTitle: 'Rank',
      shoutTitle: 'Shout',
      fitGradeTitle: 'A.I. Fit Grades',
    },
    tooltips: {
      rankTooltip:
        'Your preference for what idea(s) you most want to consider executing on',
      shoutTooltip:
        'How publicly passionate, or "shouting excited", can you get about this?',
    },
    gradingPanelLabels: {
      para1:
        'Our AI graded your fit with this idea based on each section of your profile',
      label1: 'Passions and Fun:',
      label2: 'The World:',
      label3: 'Goals:',
      label4: 'Experience:',
      label5: 'Overall:',
    },
  },
  pro: {
    title: 'Pro',
    activePlanLabel: 'Active Plan',
    activePlanMsg1: 'Your subscription to the',
    activePlanMsg2: 'plan is active. Manage your subscription in your',
    activePlanAccountSettings: 'Account Settings',
    questions: 'Questions?',
    qMsg1: 'What are Pro credits?',
    qMsg2:
      'They enable use of our more advanced AI-based features like AutoFill and Auto-Research. Most of those features use 1 credit each time, however some features take more. Those that take more make heavy use AI in multiple steps behind-the-scenes.',
    qMs3: 'For other questions see our',
    qMsg4: 'FAQ',
    qMsg5: 'or contact us at',
  },
  nonPro: {
    selectPlan: 'Select a Plan',
    noCommit: 'No Commitment',
    noCommitMsg: 'You can cancel at any time and your plan will stay active through the rest of the period you were billed for',
  },
  supportEmailID: 'contact@yourpathway.ai',
  research: {
    title: 'Research',
    ideaTab: {
      subHeading:
        'Identify the market, sub-categories, and competition for each of your ideas',
      marketSelectBlock: {
        heading: 'Market',
        dtlBtnLabel: 'Details',
        marketSelectPlaceholder: 'First select an idea above',
        addMarketBtnLabel: '+ Add Market',
        downloadReportBtnLabel: 'Download Report',
        sampleReportBtnLabel: 'Download sample report',
        subHeading: 'Sub-Categories to Use',
        addSubCatBtnLabel: '+ Add Sub-Category',
      },
      compPanel: {
        para1:
          'To define competitors first select an idea. Then set the market, and sub-category, or use Auto-Research.',
        chart: {
          chartText: 'Add a custom strength to see the radar graph',
          noCompText:
            'Add a Competitor with the button below, or by using AI Auto-Research at the top of the page',
        },
      },
      competitiveDifferentiationBlock: {
        heading: 'Competitors',
        compDiffHeading: 'Competitive Differentiation',
        compDiffPlaceholder:
          'Describe the key aspects of how your offering will be superior to the competition. Consider fundamentals like technology, and also your Unique Selling Proposition (USP) that will be how you message differentiation to your customers.',
        overAllAssessment: 'Overall Competitive Assessment',
        strengthWeaknessHeading: 'Competitor Strengths and Weaknesses',
        dtlBtnLabel: 'Details',
        marketSelectPlaceholder: 'First select an idea above',
        addMarketBtnLabel: '+ Add Market',
        downloadReportBtnLabel: 'Download Report',
        sampleReportBtnLabel: 'Download sample report',
        subHeading: 'Sub-Categories to Use',
        addSubCatBtnLabel: '+ Add Sub-Category',
      },
      deleteCompDataModal: {
        heading: 'Delete Competitor Data',
        subHeading:
          'Do you want to remove App Academy just from this Sub-Category, or delete them entirely?',
        cancelBtn: 'Cancel',
        removeBtn1: 'Remove from Sub-Category',
        removeBtn2: 'Delete them Entirely',
      },
    },
    strengthWeaknessLabel: 'Custom Strengths and Weaknesses',
    strengthsWeaknessesHeading: 'Strengths and Weaknesses',
    strengthWeaknessBtnLabel: '+ Add Strength/Weakness',
    strengthWeaknessModal: {
      nameLabel: 'Name',
      typeLabel: 'Type',
      nameFieldPlaceholder: 'Support Experience',
      scaleLabel: 'Scale',
      binaryLabel: 'Binary',
    },
    deleteStrengthWeaknessModal: {
      title: 'Delete Sub-Category Strength/Weakness',
      message1:
        'Confirm you want to delete this Strength/Weakness? It will be deleted from all competitors in this sub-category.',
    },
    addAssessmentModal: {
      title: 'Add Competitor Assessment Category',
      fieldLabel: 'Assessment',
      placeholder: 'Potential Threat, Under-Capitalized, etc.',
    },
    addMarketModal: {
      title: 'Add Market',
    },
    updateBtnLabel: 'Update',
    cancelBtnLabel: 'Cancel',
    marketTab: {
      heading: 'Market',

      noMarketMsg: 'No market list found. Please add a market to continue.',
      addMarketBtnLabel: '+ Add Market',
      subCategoriesLabel: 'Sub-Categories',
      addSubCategoryLabel: '+ Add Sub-Category',
      newSubCategoryLabel: 'New subcategory in market',
      marketCompetitorModal: {
        userAutoReserachLabel: 'Use "AI Auto-Research" on the "Ideas" tab',
        orLabel: 'Or',
        industryStandardLabel:
          'Choose an industry-standard market name. This will make identifying potential competitors easier. For options, consider referencing one of these resources:',
        naicsLabel: 'NAICS Code Categories',
        gicsLabel: 'GICS Sub-Industries (.xlsx file)',
        crunchbaseLabel: 'Crunchbase',
        techCompanyLabel1: 'For tech companies you may want to look on',
        techCompanyLabel2:
          ' another tech-focused resources for a relevant market name',
        marketFieldLabel: 'Market Name',
        competitorFieldLabel: 'Competitor Name',
        marketPlaceholder: 'Market',
        competitorPlaceholder: 'Competitor',
        marketSuggestText:
          'After you add the market, you can edit market details on the Markets tab.',
      },
      marketDeleteModal: {
        heading: 'Delete Market',
        warningLabel: 'Warning: ',
        message1:
          'Are you sure you want to delete this Market and its Sub-Categories?',
        message2:
          'this will also delete all associated competitor sub-category profile data.',
      },
      marketSubCategoryDeleteModal: {
        heading: 'Delete Sub-Category',
        message1: 'Are you sure you want to delete this Sub-Category?',
        message2:
          'this will also delete all associated competitor sub-category details.',
      },
      subCategoryPanel: {
        keyMarketChallenge: {
          label: 'Key Market Challenges',
          placeholder:
            'Highly regulated, so will require ... Few large incumbent players in this category, so go-to-market approach needs...',
        },
        keyBarrier: {
          label: 'Key Barriers to Entry',
          placeholder:
            'Complex regulatory landscape, proprietary IP required for technology to work...',
        },
      },
    },
    competitorTab: {
      heading: 'Competitors',
      noCompetitorMsg:
        'No competitor list found. Please add a competitor to continue.',
      addCompetitorBtnLabel: '+ Add Competitor',
      createCompetitorBtnLabel: '+ Create Competitor',
      newTextLabel: 'New',
      addCompetitorLabel: 'Add Competitor',
    },
    commonLabels: {
      teamSize: 'Team Size',
      funding: 'Funding (in USD)',
      annualRevenue: 'Annual Revenue (millions)',
      foundingYear: 'Founding Year',
    },
  },
  ideaDetails: {
    returnButtonLabel: '< Business Ideas',
    pageTitle: 'Idea Details',
    shareButtonLabel: 'Share',
    nameFieldLabel: 'Name',
    summaryFieldLabel: 'Summary',
    aiAutoFill: 'AI Autofill',
    pro: 'PRO',
    shareIdeaLabel: 'Share Idea',
    disableModifyLabel: 'Modify Or Disable',
    copyLinkLabel: 'Copy Link',
    emailLabel: 'Email',
    tweetLabel: 'Tweet',
    shareFacebook: 'Share on Facebook',
    shareLinkedIn: 'Share on LinkedIn',
    shareModal: {
      title: 'Modify or Disable Idea Share',
      subTitle: 'Disable/Enable Idea Share',
      warning:
        'Warning, this will immediately disable all links that were previously shared for this idea.',
      enableShareLabel: 'Enable Share',
      disableShareLabel: 'Disable Share',
      changeShareLevelLabel: 'Change Detail Level',
      ideaSummaryLabel: 'Idea Summary',
      ideaDetailedLabel: 'Detailed Idea',
      cancelBtnLabel: 'Cancel',
      updateBtnLabel: 'Update',
    },
    sharePage: {
      learnMoreLabel: 'Learn More',
      learnMorePara:
        'To learn more about Pathways and collaborate with AI to generate your own business ideas for free, visit ',
      noIdeaText: 'Sorry! No details found for the searched idea.',
    },
    tabs: {
      general: {
        tabLabel: 'General',
        descriptionLabel: 'Description',
        howItWorksLabel: 'How It Works',
        keyInnovationsLabel: 'Key Innovations',
        competitiveDifferenceLabel: 'Competitive Differentiation',
        systemTypeLabel: 'System Type (if Software)',
        myNoteLabel: 'My Notes',
      },
      customers: {
        tabLabel: 'Customers',
        lctLabel: 'Launch Customer Type(s)',
        fctLabel: 'Future Customer Type(s)',
        pdmLabel: 'Purchase Decision Maker',
        boLabel: 'Budget Owner',
        icpLabel: 'Ideal Customer Profile',
        lcpLabel: 'Launch Customer Problem',
        lcbLabel: 'Launch Customer Benefits',
      },
      goToMarket: {
        tabLabel: 'Go-To-Market',
        suggestionTip: {
          title:
            'Think about how you will acquire customers and be profitable. Use logical estimates. Definitions:',
          suggestionPoints: {
            point1:
              'Customer Acquisition Cost (CAC) = total marketing and sales costs to acquire each new customer',
            point2:
              'Deal Value: Total revenue received from an average new customer. Use for non-recurring offerings.',
            point3:
              'Annual Contract Value (ACV): Total revenue received from an average new customer each year. Use for recurring offerings.',
          },
        },
        pcamLabel: 'Primary Customer Acquisition Method(s)',
        scamLabel: 'Secondary Customer Acquisition Method(s)',
        cacLabel: 'Customer Acquisition Cost (CAC)',
        acvLabel: 'Deal or Annual Contract Value (ACV)',
        marketLabel: 'Market',
        editResearchBtnLabel: 'Edit in Research >',
      },
    },
    systemTypeTooltipData: {
      heading:
        'If your solution is software, select the system type based on these definitions:',
      point1:
        'System of Record: The source of truth for data. E.g. CRM, ERP, Email server',
      point2:
        'System of Engagement: Where the user does the work/workflow, but not the system of record. E.g. a tool like Outreach that writes data into a CRM, or an email client',
      point3:
        'System of Decision: Analysis or decision- making tools. E.g. PowerBI, Tableau',
    },
  },
  workspace: {
    title: 'Workspace',
    editButtonLabel: 'Edit Name',
    deleteButtonLabel: 'Delete Workspace',
    createButtonLabel: 'Create Workspace',
    inviteButtonLabel: 'Send Invite',
    inviteLabel: 'Invite a Collaborator',
    collaborator: 'Collaborators',
    commenterLabel: '',
    editorLabel: '',
    commenterText: 'Can view and make comments',
    editorText: 'Can edit all except your account and profile',
    existingCollaborator: 'Existing Collaborators',
    deleteNoteText:
      'Delete this workspace, any customized profile data, all ideas, and any other data unique to this workspace.',
    notDeleteMainWorkspace:
      'Note, you cannot delete your main workspace here.Contact ',
    createNoteText:
      'You can create an additional workspace with a unique profile, ideas, markets, and competitors.',
    ownerLabel: 'Owner',
    updateWorkspaceLabel: 'Update Workspace',
    workspaceNameInputLabel: 'Workspace name',
    cancelButtonLabel: 'Cancel',
    deleteConfirmLabel:
      'Are you sure you want to delete your this workspace? You will permanently lose access to the data unique to this workspace.',
    deleteWorkspacePopupHeading: 'Delete this Workspace',
    guest: {
      inviteCollabMessage1:
        'You must be the workspace owner to invite or edit collaborators.',
      inviteCollabMessage2:
        'Switch to a workspace you own, or ask the workspace owner to invite collaborators.',
    },
  },
  careerPathways: {
    title: 'Your Career Pathways',
    generatePathways: 'Generate Pathways',
    regeneratePathways: 'Regenerate Pathways',
    noDataFoundLabel: 'Please click on Generate Pathways.',
    guestAccessDeniedLabel:
      'Access to Career Pathways in guest workspace is denied.',
  },
  comments: {
    title: 'Comments and Changes',
    commentsTab: {
      addCommentPlaceholder: 'Write a comment',
      addCommentButtonLabel: 'Add Comment',
      associationCheckboxText: 'Associate with current page',
      replyPlaceholder: 'Reply',
    },
  },
  manageCollabButton: 'Manage Collaborators',
  thankyou: 'Thank You!',
  paymentSuccess: 'Your payment was successful',
  sorry: 'Sorry!',
  paymentFail: 'Your payment was failed',
};

export const errors = {
  login: {
    otpBlank: 'OTP cannot be left blank!',
    invalidOtp: 'Invalid OTP!',
    otpSize: 'OTP cannot be less than six characters',
    validEmail: 'Please enter valid email address',
    emailRequired: 'Please enter your email address.',
  },
  accounts: {
    emailNotChanged: 'Email address not changed!',
    emailNotCorrect: 'Email address is not correct!',
    incorrectFirstName: 'Invalid first name!',
    incorrectLastName: 'Invalid last name!',
    invalidLength: 'Cannot be more than 40 characters',
    invalidMinLength: 'Cannot be less than 2 characters',
    invalidCountry: 'Please select a country!',
    selectARole: 'Please select a role!',
    alreadyActiveRole: 'This is your current role, please select another role!',
  },
  businessIdeas: {
    addIdeaModal: {
      ideaName: 'Idea name cannot be left blank!',
      ideaSummary: 'Idea summary cannot be left blank!',
    },
  },
  ideaDetails: {
    ideaNameBlank: 'Idea name cannot be left blank!',
    ideaSummaryBlank: 'Idea summary cannot be left blank!',
  },
};
export const warnings = {
  accounts: {
    note: 'Please Note!',
    emailChange:
      'Please verify your new email address. You will receive a one-time password (OTP) shortly.',
    confirmText: 'Do you want to continue with the change?',
    confirmBtnText: 'Yes',
    cancelBtnText: 'No',
  },
};

export const placeholders = {
  accounts: {
    selectRole: 'Please select a role',
    selectCountry: 'Please select a country',
  },
};
export const regexes = {
  nameValidation: /^[A-Za-z\u0000-\u007F\u0080-\u00FF\u0100-\u017F\s'-.]+$/imu,
  emailValidation:
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
};

export const limitations = {
  nameMaxLimitation: 40,
  nameMinLimitation: 2,
};

export const proPackDetails = {
  pro50Pack: {
    planId: 1,
    pro50Name: 'Pro-50',
    pro50Price: '$39/mo',
    pro50Details: [
      'Everything in the Free plan',
      'ProLabel',
      'Multiple <b>Workspaces</b>',
      'Create and Export <b>Business Plans</b>',
      'Multi-User <b>Collaboration</b>',
      'Premium GPT-4 A.I. engine for smarter ideas and vetting',
      'Future access to other Pro features in development right now',
    ],
    pro50UpgradeBtn: 'Upgrade to Pro-50',
  },
  proUnlimitedPack: {
    planId: 2,
    proUnlimitedName: 'Pro Unlimited',
    proUnlimitedPrice: '$99/mo',
    proUnlimitedDetails: [
      'Everything in the Pro 50 plan',
      'ProLabel',
      'Freedom and peace-of-mind using Pro credits at any time',
    ],
    proUnlimitedUpggradeBtn: 'Upgrade to Pro Unlimited',
  },
  proAnnualPack: {
    planId: 3,
    proAnnualName: 'Pro Annual',
    proAnnualOldPrice: '$1188',
    proAnnualPrice: '$699/yr',
    proAnnualDetails: [
      'Everything in the Pro Unlimited plan',
      'ProLabel',
      'A full year of deeply-discounted access!',
    ],
    proAnnualUpgradeBtn: 'Upgrade to Pro Annual',
    annualDiscount: 'Annual 40% Discount',
  },
};

export const proModalsContent = {
  createWorkspace: {
    heading: 'Create Workspace',
    noteText1: `Multiple Workspaces is one of our powerful Pro features.`,
    noteText2: `Upgrade now to get access to this and the other Pro features!`,
    proLabel: 'PRO',
  },
  aiAutofill: {
    heading: 'Upgrade for AI Autofill',
    noteText1: `AI Autofill is one of our powerful Pro features.`,
    noteText2: `You've used up 5 of your 5 included Pro credits and more credits are needed for your request. Upgrade now to get access to this and the other Pro features.`,
    proLabel: 'PRO',
  },
  inviteCollaborator: {
    heading: 'Upgrade for Workspace Collaborators',
    noteText1: `Workspace Collaborators is one of our powerful Pro features.`,
    noteText2: `Upgrade now to get access to this and the other Pro features!`,
    proLabel: 'PRO',
  }
}