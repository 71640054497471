import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { proPackDetails } from "../../../data/constants";
import { labels } from "../../../data/constants";

const PlanSelectionFormControl = ({ selectedPlanId, setSelectedPlanId }) => {
  return (
    <FormControl className="width100 colorBlack">
      <FormLabel className="fontSize14 fontWeightBold">
        {labels.accounts.selectPlanHeading}
      </FormLabel>
      <RadioGroup
        aria-labelledby="pro-plan-option"
        name="pro-plan-radio-select"
        value={selectedPlanId} // Assuming selectedPlanId is the state variable for the selected plan
      >
        {/* Mapping over an array of plan details */}
        {[proPackDetails.proAnnualPack, proPackDetails.proUnlimitedPack, proPackDetails.pro50Pack].map((pack) => {
          const { planId } = pack;
          let planName, oldPrice, price, discount;

          // Extract the correct properties based on the pack type
          if (pack.proAnnualName) {
            planName = pack.proAnnualName;
            oldPrice = pack.proAnnualOldPrice;
            price = pack.proAnnualPrice;
            discount = pack.annualDiscount;
          } else if (pack.proUnlimitedName) {
            planName = pack.proUnlimitedName;
            price = pack.proUnlimitedPrice;
          } else if (pack.pro50Name) {
            planName = pack.pro50Name;
            price = pack.pro50Price;
          }

          return (
            <FormControlLabel
              key={planId}
              value={planId}
              control={<Radio />}
              label={
                <>
                  {planName} -
                  {oldPrice && <span className="lineThrough">{oldPrice}</span>} {price}
                  {discount && <Typography className="ml1 mt1p" variant="badgeText2">{discount}</Typography>}
                </>
              }
              aria-label={`Select ${planName} plan`} // Accessibility improvement
              onChange={() => setSelectedPlanId(planId)} // Set the selected plan on change
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default PlanSelectionFormControl;
