import {
  Box,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { labels } from '../../../data/constants';
import CustomSelect from '../../Custom Components/CustomSelect/CustomSelect';
import { useEffect, useState } from 'react';
import AddMarketOrCompetitorModal from '../AddMarketOrCompetitorModal';
import SubCategoryModal from '../Market/SubCategory/SubCategoryModal';
import { useSelector } from 'react-redux';
import {
  createMarketSubCategoryService,
  createResearchIdeaService,
  getResearchIdeaService,
  updateResearchIdeaService,
  downloadReport
} from '../../../Services/ResearchServices';
import { useDispatch } from 'react-redux';
import {
  setSelectedIdea,
  setSelectedMarketOption,
} from '../../../redux/researchSlice';
import { saved, saving, couldnotSave } from '../../../redux/saveSlice';
import { logChanges } from '../../../Services/commentService';

const MarketSelectBlock = ({
  marketWithCatDtls = [],
  fetchMarketWithSubCats = () => { },
  AddMarket = () => { },
  setSelectedTab = () => { },
}) => {
  const dispatch = useDispatch();
  const selectedIdea = useSelector(
    (state) => state?.researchData?.selectedIdea
  );
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );
  const selectedMarketOption = useSelector(
    (state) => state?.researchData?.selectedMarketOption
  );
  //To store market options list.
  const [marketOptions, setMarketOptions] = useState([]);
  //To store market id and label about the selected market option.
  const [selectedMarket, setSelectedMarket] = useState(null);
  //To store the market and its linked subcats info which is linked to an idea.
  const [selectedMarketDetails, setSelectedMarketDetails] = useState(null);
  const [selectedMarketSubCats, setSelectedMarketSubCats] = useState(null);
  const [showAddMarketModal, setShowAddMarketModal] = useState(null);
  const [showAddSubCatModal, setShowAddSubCatModal] = useState(false);
  useEffect(() => {
    if (marketWithCatDtls.length) {
      let marketOptions = marketWithCatDtls.map((marketOption) => ({
        label: marketOption?.market_name,
        value: marketOption?.market_id,
      }));
      setMarketOptions(marketOptions);
    }
  }, [marketWithCatDtls]);
  useEffect(() => {
    setSelectedMarket(null);
    setSelectedMarketDetails(null);
    if (!selectedIdea) {
      return;
    }
    if (selectedMarketOption) {
      let matchedMarket = marketOptions.filter(
        (marketOpt) => marketOpt?.value === selectedMarketOption?.market_id
      );
      setSelectedMarket(matchedMarket[0]);
      setSelectedMarketDetails(selectedMarketOption);
    } else {
      if (selectedIdea?.marketList?.length && marketOptions?.length) {
        let matchedMarket = marketOptions.filter(
          (marketOpt) =>
            marketOpt?.value === selectedIdea?.marketList[0]?.market_id
        );
        setSelectedMarket(matchedMarket[0]);
        setSelectedMarketDetails(selectedIdea?.marketList[0]);
        dispatch(setSelectedMarketOption(selectedIdea?.marketList[0]));
      }
    }
  }, [selectedIdea, marketOptions, selectedMarketOption]);

  useEffect(() => {
    if (selectedMarket) {
      setSelectedMarketSubCats(null);
      let subCats = marketWithCatDtls?.filter(
        (marketOpt) => marketOpt?.market_id === selectedMarket?.value
      );
      setSelectedMarketSubCats(subCats);
    } else {
      setSelectedMarketSubCats(null);
    }
  }, [selectedMarket, marketWithCatDtls]);

  const handleSelectChange = async (selectedMarket) => {
    let marketAlreadyPresentInList;
    let foundAtIndex;
    if (selectedIdea?.marketList) {
      selectedIdea?.marketList?.forEach((marketOption, index) => {
        if (marketOption?.market_id === selectedMarket?.value) {
          marketAlreadyPresentInList = true;
          foundAtIndex = index;
          return;
        } else {
          marketAlreadyPresentInList = false;
        }
      });
    } else {
      marketAlreadyPresentInList = false;
    }
    if (!marketAlreadyPresentInList) {
      try {
        let payload = {
          workspaceId: userWorkspace?.id,
          ideaId: selectedIdea?.id,
          marketId: selectedMarket?.value,
        };
        dispatch(saving());
        const response = await createResearchIdeaService(payload, dispatch);
        if (response) {
          dispatch(saved());
          let payload = {
            workspaceId: userWorkspace?.id,
            ideaId: selectedIdea?.id,
          };
          const response = await getResearchIdeaService(payload);
          if (response) {
            let ideaInfo = { ...response?.idea };
            ideaInfo['marketList'] = [...response?.researchIdeaDetails];
            let marketOption = ideaInfo?.marketList?.filter(
              (option) => option?.market_id === selectedMarket?.value
            );
            dispatch(setSelectedMarketOption(marketOption[0]));
            dispatch(setSelectedIdea(ideaInfo));
          }
          let changeLogPayload = {
            changeDetail: `Idea '${selectedIdea.name}' updated`,
            workspaceId: userWorkspace.id,
          };
          await logChanges(changeLogPayload, dispatch);
        }
      } catch (e) {
        dispatch(couldnotSave());
        console.log(e);
      }
    } else {
      setSelectedMarket(selectedMarket);
      setSelectedMarketDetails(selectedIdea?.marketList[foundAtIndex]);
      dispatch(setSelectedMarketOption(selectedIdea?.marketList[foundAtIndex]));
    }
  };
  const handleSwitchChange = async (e, subCatId) => {
    try {
      let value = e.target.checked;
      let payload = {
        workspaceId: userWorkspace?.id,
        subcategoryId: subCatId,
        subcategoryFlag: value.toString(),
        researchIdeaDetailsId: selectedMarketDetails?.id,
      };
      dispatch(saving());
      const response = await updateResearchIdeaService(payload, dispatch);
      if (response) {
        dispatch(saved());
        let ideaDetail = JSON.parse(JSON.stringify(selectedIdea));
        let selectedMarketIndex;
        ideaDetail?.marketList?.forEach((opt, index) => {
          if (opt?.market_id === selectedMarketDetails?.market_id) {
            selectedMarketIndex = index;
          }
        });
        if (value) {
          if (ideaDetail?.marketList[selectedMarketIndex].subcategory_id) {
            ideaDetail?.marketList[selectedMarketIndex].subcategory_id?.push(
              subCatId
            );
            dispatch(setSelectedIdea(ideaDetail));
            dispatch(
              setSelectedMarketOption(
                ideaDetail?.marketList[selectedMarketIndex]
              )
            );
          } else {
            ideaDetail.marketList[selectedMarketIndex].subcategory_id = [];
            ideaDetail.marketList[selectedMarketIndex].subcategory_id?.push(
              subCatId
            );
            dispatch(setSelectedIdea(ideaDetail));
            dispatch(
              setSelectedMarketOption(
                ideaDetail?.marketList[selectedMarketIndex]
              )
            );
          }
        } else {
          const index =
            ideaDetail?.marketList[selectedMarketIndex].subcategory_id?.indexOf(
              subCatId
            );
          if (index > -1) {
            ideaDetail?.marketList[selectedMarketIndex].subcategory_id?.splice(
              index,
              1
            );
            dispatch(setSelectedIdea(ideaDetail));
            dispatch(
              setSelectedMarketOption(
                ideaDetail?.marketList[selectedMarketIndex]
              )
            );
          }
        }
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const addNewSubCat = async (subCategoryName, isNewSubCategory) => {
    try {
      let payload = {
        marketId: selectedMarket?.value,
        subCategoryName: subCategoryName,
        newSubCategory: isNewSubCategory,
      };
      const response = await createMarketSubCategoryService(
        payload,
        dispatch,
        true
      );
      if (response) {
        await fetchMarketWithSubCats();
        let payload = {
          workspaceId: userWorkspace?.id,
          subcategoryId: response?.id,
          subcategoryFlag: 'true',
          researchIdeaDetailsId: selectedMarketDetails?.id,
        };
        const addResponse = await updateResearchIdeaService(payload, dispatch);
        if (addResponse) {
          let ideaDetail = JSON.parse(JSON.stringify(selectedIdea));
          let selectedMarketIndex;
          ideaDetail?.marketList?.forEach((opt, index) => {
            if (opt?.market_id === selectedMarketDetails?.market_id) {
              selectedMarketIndex = index;
            }
          });
          if (ideaDetail?.marketList[selectedMarketIndex].subcategory_id) {
            ideaDetail?.marketList[selectedMarketIndex].subcategory_id?.push(
              response?.id
            );
            dispatch(setSelectedIdea(ideaDetail));
            dispatch(
              setSelectedMarketOption(
                ideaDetail?.marketList[selectedMarketIndex]
              )
            );
          } else {
            ideaDetail.marketList[selectedMarketIndex].subcategory_id = [];
            ideaDetail.marketList[selectedMarketIndex].subcategory_id?.push(
              response?.id
            );
            dispatch(setSelectedIdea(ideaDetail));
            dispatch(
              setSelectedMarketOption(
                ideaDetail?.marketList[selectedMarketIndex]
              )
            );
          }
          setShowAddSubCatModal(false);
        }
        let changeLogPayload = {
          changeDetail: `Idea '${selectedIdea.name}' updated`,
          workspaceId: userWorkspace.id,
        };
        await logChanges(changeLogPayload, dispatch);
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  };
  const handleAddMarket = async (marketName) => {
    const response = await AddMarket(marketName);
    if (response) {
      setShowAddMarketModal(false);
    }
  };

  const handleDownload = async () => {
    try {
      let payload = {
        workspaceId: userWorkspace?.id,
        marketId: selectedMarket?.value,
        subcategoryId: selectedMarketDetails?.subcategory_id,
        ideaId: selectedIdea?.id,

      };
      dispatch(saving());
      const response = await downloadReport(payload, dispatch);
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response]));        // const url = window.URL.createObjectURL(new Blob([pdfBlob]));
        const tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.setAttribute('download', 'marketreport.pdf');
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
        dispatch(saved());
      }
    } catch (e) {
      dispatch(couldnotSave());
      console.log(e);
    }
  }
  return (
    <Box className="border3 borderColorBlue mt1 borderRadius8 padding1">
      <Grid container columnSpacing={4}>
        <Grid item lg={6}>
          <Box className="displayFlex alignItemsCenter">
            <Typography
              className="mt1 mb1 mr05"
              variant="dashboardCompHeading5"
            >
              {labels.research.ideaTab.marketSelectBlock.heading}
            </Typography>
            <Button
              className="ml0"
              variant="textButton1"
              disabled={selectedIdea ? false : true}
              onClick={() => {
                if (selectedMarket) {
                  setSelectedTab(1);
                }
              }}
            >
              {labels.research.ideaTab.marketSelectBlock.dtlBtnLabel}
            </Button>
          </Box>
          <CustomSelect
            selectedValue={selectedMarket}
            handleChange={(value) => {
              handleSelectChange(value);
            }}
            dropDownItems={marketOptions}
            multi={false}
            customStyles={{
              marginTop: '0.5rem',
              minHeight: '40px',
            }}
            disabled={selectedIdea ? false : true}
          />
          <Box className="displayFlex justifySpaceBetween mt1">
            <Button
              disabled={selectedIdea ? false : true}
              onClick={() => {
                setShowAddMarketModal(true);
              }}
              className="textTrasformCapital"
              variant="buttonVariant1"
            >
              {labels.research.ideaTab.marketSelectBlock.addMarketBtnLabel}
            </Button>
            <Box className="displayFlex flexDirectionColumn">
              <Button
                className="textTrasformCapital"
                disabled={selectedMarket?.value ? false : true}
                variant="buttonVariant1"
                onClick={handleDownload}
              >
                {
                  labels.research.ideaTab.marketSelectBlock
                    .downloadReportBtnLabel
                }
              </Button>
              <Button
                className="textTrasformCapital mt05"
                variant="textButton2"
              >
                {labels.research.ideaTab.marketSelectBlock.sampleReportBtnLabel}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6}>
          {
            <Box component={'div'}>
              <Typography
                component={'p'}
                className="mt1 mb1 mr1"
                variant="dashboardCompHeading5"
              >
                {labels.research.ideaTab.marketSelectBlock.subHeading}
              </Typography>
              {selectedMarketSubCats &&
                selectedMarketSubCats[0]?.sub_categories?.map(
                  (subCatOption, index) => (
                    <FormControlLabel
                      className="displayBlock"
                      key={'subCatOption' + index}
                      label={subCatOption?.sub_category_name}
                      control={
                        <Switch
                          onChange={(e) => {
                            handleSwitchChange(
                              e,
                              subCatOption?.sub_category_id
                            );
                          }}
                          checked={
                            selectedMarketDetails?.subcategory_id?.includes(
                              subCatOption?.sub_category_id
                            )
                              ? true
                              : false
                          }
                        />
                      }
                    />
                  )
                )}
              <Button
                className="textTrasformCapital mt1"
                variant="buttonVariant1"
                disabled={!selectedMarketSubCats ? true : false}
                onClick={() => {
                  setShowAddSubCatModal(true);
                }}
              >
                {labels.research.ideaTab.marketSelectBlock.addSubCatBtnLabel}
              </Button>
            </Box>
          }
        </Grid>
      </Grid>
      {showAddMarketModal && (
        <AddMarketOrCompetitorModal
          toAdd={'Market'}
          dialogTitle={labels.research.addMarketModal.title}
          handleClose={() => {
            setShowAddMarketModal(false);
          }}
          submitMarketDetail={handleAddMarket}
        />
      )}
      {showAddSubCatModal && (
        <SubCategoryModal
          handleCancel={() => {
            setShowAddSubCatModal(false);
          }}
          submitDetails={addNewSubCat}
        />
      )}
    </Box>
  );
};
export default MarketSelectBlock;
