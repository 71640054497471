import DashboardLayout from '../Layouts/DashboardLayout';
import PaymentFailureComp from './PaymentFailureComp';

const PaymentFailureContainer = () => {
  return (
    <DashboardLayout>
      <PaymentFailureComp></PaymentFailureComp>
    </DashboardLayout>
  );
};
export default PaymentFailureContainer;