import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
// import DashboardContainer from '../Screen/Dashboard/index';
import 'react-toastify/dist/ReactToastify.css';
import { MaintenanceRoute } from './MaintenanceRoute';
import MaintenanceLayout from '../Screen/Layouts/MaintenanceLayout';
import { ToastContainer } from 'react-toastify';
import Login from '../Screen/Login/login';
import Home from '../Screen/Home';
import Signup from '../Screen/Signup/Signup';
import DashboardContainer from '../Screen/Dashboard/Dashboard';
import ProfileContainer from '../Screen/Profile/Profile';
import AccountContainer from '../Screen/Account/Account';
import BusinessIdeasContainer from '../Screen/BusinessIdeas/BusinessIdeas';
import BusinessPlanContainer from '../Screen/BusinessPlan/BusinessPlan';
import ProContainer from '../Screen/Pro/Pro';
import FilterContainer from '../Screen/Filter/Filter';
import ResearchContainer from '../Screen/Reasearch/Research';
import IdeaDetailsContainer from '../Screen/IdeaDetails/IdeaDetails';
import IdeaSharePage from '../Screen/IdeaShare/IdeaSharePage';
import WorkspaceContainer from '../Screen/Workspace/Workspace';
import InvitationContainer from '../Screen/Invite/InvitationContainer';
import CareerPathwayContainer from '../Screen/CareerPathway/CareerPathwayContainer';
import PaymentSuccessContainer from '../Screen/PaymentSuccess/PaymentSuccessContainer';
import PaymentFailureContainer from '../Screen/PaymentSuccess/PaymentFailureContainer';

const AllRoutes = ({ user, isMaintenance }) => {
  return (
    <>
      <ToastContainer />
      <Routes>
        {/* @ts-ignore */}
        <Route element={<MaintenanceRoute isMaintenance={isMaintenance} />}>
          {/* ============= LOGEDOUT ROUTES ========== */}
          {/* <Route index element={<Navigate to={'/app'} />} />
          <Route path="app"> */}
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="idea/:id" element={<IdeaSharePage />} />
          {/* <Route path="create-account" element={<SignupForm />} />
            
            {/* </Route> */}
          {/* ============= LOGEDIN ROUTES ========== */}
          {/* @ts-ignore */}
          <Route element={<ProtectedRoute isAllowed={!!user} />}>
            <Route path="dashboard" element={<DashboardContainer />} />
            <Route path="profile" element={<ProfileContainer />} />
            <Route path="account" element={<AccountContainer />} />
            <Route path="businessideas" element={<BusinessIdeasContainer />} />
            <Route
              path="businessideas/:id"
              element={<IdeaDetailsContainer />}
            />
            <Route path="businessplan" element={<BusinessPlanContainer />} />
            <Route path="pro" element={<ProContainer />} />
            <Route path="filter" element={<FilterContainer />} />
            <Route path="research" element={<ResearchContainer />} />
            <Route path="workspace" element={<WorkspaceContainer />} />
            <Route path="invite" element={<InvitationContainer />} />
            <Route path="careerpathway" element={<CareerPathwayContainer />} />
            <Route path="paymentSuccess" element={<PaymentSuccessContainer />} />
            <Route path="paymentFailure" element={<PaymentFailureContainer />} />
          </Route>
        </Route>
        {/* ============= COMMON ROUTES ========== */}

        {/* </Route> */}
        <Route path="*" element={<h1>404 Page Not Found</h1>} />
        <Route path="maintenance" element={<MaintenanceLayout />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
