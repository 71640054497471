import {
  Box,
  Typography,
} from '@mui/material';
import { labels } from '../../data/constants';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { setUserDetails } from '../../redux/login';
import { getUserDetailService } from '../../Services/AccountServices';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const PaymentSuccessComp = () => {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   getUserDetails();
  // }, []);

  // const getUserDetails = async () => {
  //   const response = await getUserDetailService(dispatch);
  //   if (response) {
  //     dispatch(setUserDetails(response));
  //   }
  // };
  return (
    <>
      <Box mt={2} mr={2} pb={3}>
        <Typography
          component={'h2'}
          mt={5}
          fontSize={24}
          fontWeight={'bold'}
          textAlign={'center'}
        >
          {labels.thankyou}
        </Typography>
        <CheckCircleOutlineIcon className='enlargedIcon m5x34x2x34' fontSize='large' sx={{ color: 'green' }} />
        <Typography
          component={'h2'}
          mt={5}
          textAlign={'center'}
        >
          {labels.paymentSuccess}
        </Typography>
      </Box>
    </>
  );
};

export default PaymentSuccessComp;
